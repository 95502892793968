.brew_dialog {
  box-sizing: border-box;
  position:relative;
  width: 100vw;  
  height:100vh;
  max-width:100%;
  max-height:100%;
  padding:0;
  margin:0;
  background-color: var(--body-color);
  border-width:1px;
  border-radius:0.5rem;  
}

.brew_dialog_inner {  
  display:flex;
  width:100%;
  flex-direction:column;
  margin-inline:1px;
  border-style:none;
}

.brew_dialog_inner .steps {
  width:100%;
}

.brew_dialog_inner .content {
  flex-grow:1;
  display:flex;
  width:100%;
  flex-direction:row;
}

.brew_dialog_inner header {
  position:sticky;
  background-color:var(--body-color, tan);
  top:0;
  z-index:1;
  display:flex;
  flex-direction: row;
  text-align:center;
  margin-top: 0em;
  margin-bottom:1px;
  margin-left:auto;
  margin-right:auto;
  border-bottom:solid 1px;
  width:100%;
  
}

.brew_dialog_inner header h1 {
  flex-grow:1;
}

.brew_dialog header .zone_clear_btn {
  position:absolute;
  flex-grow:0;
  font-size:1.2rem;
  width:2.5rem;
  height:2.5rem;
  top:0;
  right:0;
}
.brew_dialog header sound-status button {
  background-color: transparent;
  border:none;
}
.steps .hidden {
  display:none;
}

.brew_dialog .field {
  width:100%;
  display:grid;
  grid-template-columns:7rem minmax(auto, 15rem);
}

.brew_dialog .dilute .instructions {
  margin-bottom:3em;
}
.brew_dialog .dilute .instructions > p{
  margin-bottom:0px;
}
.brew_dialog .dilute .not_ready {
  max-width:40ch;
  display:flex;
  flex-direction:row;
  font-size:0.8rem;
  margin-inline:1rem;
}
.brew_dialog .dilute .not_ready > span {
  padding-left:0.25rem;
}
.brew_dialog .dilute .not_ready button {
  margin: auto;
}

.target-density-ui-root {
  display:flex;
  flex-direction: column;
  width:100%;
}