
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 100,
  'GRAD' 0,
  'opsz' 24
}

.material-symbols-outlined.material-nofill{
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 24
}

.history .record > button .material-symbols-outlined,
.small.material-symbols-outlined  {
  font-variation-settings:
  'FILL' 1,
  'wght' 100,
  'GRAD' 0,
  'opsz' 20
}

.material-nofill.small.material-symbols-outlined  {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 20
}

.material-symbols-outlined.inline-size {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 20;
  font-size:1.5em;
  position:relative;
  top: 0.35rem;
  
}