html, body {
  width:100vw;
  max-width:60rem;
  margin-inline:auto;
  margin-block:0px;
}

:root{
  --old_value_display:none;
  --accent-color:brown;
  --header-color:tan;
  --body-color:antiquewhite;
  --disabled-color:rgb(238, 238, 238);

  --button_bg_color:tan;
  --button_border_color:tan;
  --units-metric-display:none;
  --units-std-display:initial;
}

.units-metric {
  display:var(--units-metric-display);
}
.units-std {
  display:var(--units-std-display);
}

button {
  background-color:var(--button_bg_color);
  border-color:var(--button_border_color);
  border-radius:10%;
  border-width:1px;
}
.settings-button {
  position:relative;
  
}

input[type="number"] {
   -moz-appearance: textfield;
   appearance:textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.component-menu {
  background-color:var(--body-color);
  border-style:solid;
  border-width:1px;
  position:absolute;
  top:100%;
  right:0;
  z-index:1;
}
button:focus-within .component-menu, .component-menu:focus-visible{
  display:initial;
}
.component-menu fieldset {
  display:flex;
  flex-direction:row;
}
button .component-menu {
  display:none;
}

.units-setting >div {
  display:flex;
  flex-direction:row;
}

textarea, input, select {
  font-size:1rem;
}
label {
  margin-block:auto;
}
* {
  box-sizing:border-box;
}

.hidden {
  display:none;
}

.body_content {
  width:100%;
  background-color:var(--body-color);
  min-height:100vh;  
}

.body_content > header {
  position:sticky;
  z-index:1;
  top:0;
  display:flex;
  text-align:center;
  background-color:var(--header-color);
  padding:0.2rem;
  margin-bottom:0.5rem;

}

.body_content header h1 {
  flex-grow:1;
  font-size:1.2rem;
  margin:0;
  margin-block:auto;
  display:flex;
  flex-direction:column;
}
.body_content header h1 .subtitle {
  font-size:1.0rem;
}
.body_content header h1 .separator {
  display:none;
}

@media (min-width:47ch) {
  .body_content header h1 {
    display:block;
  }
  .body_content header h1 .separator {
    display:inline;
  }
  .body_content header h1 .subtitle {
    font-size:1.2rem;
  }
}
.body_content > header button {
  border:none;
}

.body_content .coffee_details header{
  padding-left:0.5em;
  border-style:groove none none groove;
  border-radius:0.5rem;
  border-color:var(--accent-color);
  text-align:left;
}

.content_area {
  margin-bottom:0.5rem;
}

.history .record {
  border-style:groove none none groove;
  border-radius:0.5rem;
  border-color:var(--accent-color);
}
.coffee_details header h2{
  font-size:1.0rem;
}

.coffee_details {
  width:100%;
}

.coffee_source_root {
  display:flex;
  flex-direction: column;
  width:100%;
}

.field {
  /* position:relative; */
  width:100%;
  display:grid;
  grid-template-columns:auto 1fr 1fr;
}

.field input, .field select, .field textarea {
  display:block;
  min-width:0px;  
  flex-shrink:1;
  flex-grow:1;
}

.field .old_value:is(input, select, textarea) {
  background-color:var(--disabled-color);
}

.brew_config_root header {
  display:flex;
  flex-direction:row;
}
.brew_config_root header button {
  display:var(--old_value_display);
  border-top-right-radius:0.5rem;
  margin-block:auto;
  margin-left:auto;
}
.brew_config_root .field label {
  text-align:right;
  flex-shrink:0;
  width: 10rem;
  margin-right:0.5rem;
}
.brew_config_root .field .hidden {
  display:none;
}
.target-density-ui-root {
  border-width:1px;    
  margin-bottom:0.2rem;
  border-bottom-style:solid;
  border-color:var(--accent-color);
  padding-bottom:0.2rem;
}


.target-density-ui-root h3 {
  margin-block:2px;
  text-align:center;
  font-size:1rem;
  font-style:normal;
  font-weight:400;
}
.coffee_source_root .field label {
  text-align:right;
  flex-shrink:0;
  width:4rem;
  margin-right:0.5rem;
}


.field .old_value {
  display:var(--old_value_display);
}

.start_brew_btn > .btn_text {
  margin-block:auto;
}
