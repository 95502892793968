dialog {
  border-width:1px;
  border-radius:0.5rem;
  background-color: var(--body-color);  
}

.history_dialog .hidden {
  display:none;
}
.history_dialog {
  width: 95vw;
  margin-top:1.5rem;
  min-height:30vh;

  max-width:60rem;
  padding:0px;
}

.history_dialog header h1 {
  font-size:1.2rem;
  margin:0;
}

.history_dialog header {
  position:sticky;
  top:0;
  z-index:1;
  background-color:var(--header-color);
  text-align:center;
  border-bottom-style:inset;
}

.history_dialog .close_btn {
  font-size:1.0rem;  
  position:absolute;
  top:0;
  right:0;
  border-top-right-radius:0.5rem;
}

.history_dialog fieldset > div {
  width:100%;
  display:flex;
  flex-direction:row;
  gap:0.1em;
  flex-wrap:wrap;  
}
.history_dialog fieldset .fieldset_item  {
  display:flex;
  flex-direction:row;
  flex-shrink:0;
  text-align: center;
  width:fit-content;
  margin-inline:0.2em;  
}

.EditableBrewRecord.selected {
  box-shadow: 0 0 0.2rem 0.2rem inset var(--accent-color);
  transition: box-shadow 250ms;
}
.DeleteConfirmationDialog > div {
  width:100%;
}
.DeleteConfirmationDialog .close {
  float:right;
}
