@import url("../css/material/material.css");

.EditableBrewRecord {
  position:relative;
  --body-color:var(--EditableBrewRecord-body-color, bisque);
  background-color:var(--body-color);
  display:flex;
  flex-direction:column;
  padding:0.5rem;
  border-bottom-style:ridge;
  cursor:pointer;
}

timestamp-field, editable-coffee-source, editable-brew-results {
  width:100%;
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:0.5rem;
}

timestamp-field .timestamp, editable-coffee-source .name, editable-brew-results .notes {
  grid-column:span 2;
}

:is(timestamp-field, editable-coffee-source, editable-brew-results) label {
  display:none;
}
.EditableBrewRecord select {
  border-radius:0.25rem;
  padding:1px 4px 1px 4px;
}

.EditableBrewRecord .field {
  overflow:hidden;
  width:100%;
  display:flex;
  flex-direction:row;
}

.EditableBrewRecord .field :not(label) {
 flex:1;
}

.EditableBrewRecord .field label {
  flex-grow:0;
  width:10ch;
}

.EditableBrewRecord[editmode="false"].compact .notes {
  display:none;
}

.EditableBrewRecord[editmode="false"] :is(input, select, textarea) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  pointer-events:none;
  background-color:transparent;
  border:none;
}

.EditableBrewRecord .edit_btn, .EditableBrewRecord .delete_btn {
  background-color:var(--body-color, transparent);
  padding:0.2rem;
}
.bad { 
  background-color:orange !important;  
  
}

.good {
  background-color:lightgreen !important;
  
}

input, select, textarea{
  font-size:1rem;
}

