.history {
  display:flex;
  flex-direction: row; 
}

.history header {
  width:100%;
  display:flex;
  flex-direction: row;
  padding-bottom:0.2rem;
  text-align:center;
}

.history header span {
  flex-grow:1;
  margin-block:auto;
}

.history > button {
  margin-bottom:auto;
}
.history .record { 
  overflow:hidden;
  flex-grow:1;
  flex-shrink:1;
  
  margin-left:auto;
  
  position:relative;
  border-style:solid;
  border-width:1px;
  display:flex;
  flex-direction:column;
}
.history .record.hidden {
  display:none;
}
.zone_clear_btn {
  background-color:rgb(0,0,0,0);
  border:none;
  padding:0;
}

.history .record .zone_clear_btn {
  flex-grow:0;
}

.history .record .history_field {
  min-width:0px;
  display:flex;
  flex-direction:row;
  margin-inline:0.5rem;
}

.history .record .history_field label {
  width:9ch;
  flex-shrink:0;
  flex-grow:0;
}

.history .record > div :is(input, textarea) {
  display:block;
  min-width:0px;  
  flex-shrink:1;
  flex-grow:1;
}
.history .record textarea {
  background-color:var(--disabled-color);
}
.history .good {
  background-color:lightgreen;
}
.history .bad {
  background-color:orange;
}