.CountdownTimerContainer {
  width:100%;
  font-size:3rem;
}

.inner_timer {
  width:fit-content;
  min-width:3rem;
  margin-inline:auto;
  border-style:solid;
  padding:1rem;
  border-width:1px;
  border-radius:50%;
  cursor:pointer;
  text-align:center;
  
}

.inner_timer.expiring {
  animation-name:TimerExpiring;
  animation-iteration-count: var(--expire-animation-count, 6);
  animation-duration:var(--expire-animation-duration, 500ms);
}

.inner_timer.active {
  background-color:lightgreen;
}
.inner_timer.expired {
  background-color:red;
}

@keyframes TimerExpiring {
  0% {
    box-shadow: 0rem 0rem 0rem 0rem black;
  }
  50% {
    box-shadow: 0rem 0rem 0.5rem 0.125rem blue, 0rem 0rem 0.5rem 0.125rem inset blue;
  }

  100% {
    box-shadow: 0rem 0rem 0rem 0rem black;
  }
}