update-notification-icon-ui {
  position:relative;
}

update-notification-icon-ui .popup-menu.hidden {
  display:none;
}

update-notification-icon-ui .popup-menu {
  background-color:var(--header-color);
  width:24ch;
  max-width:90vw;
  position:absolute;
  top:100%;
  left:0%;
  z-index:1;
  padding:0rem 1rem 1rem 1rem;
  border-width:0px 1px 1px 1px;
  border-style:solid;
  border-radius:0rem 0.5rem 0.5rem 0.5rem 
}

update-notification-icon-ui .update {
  border:0px;
  animation-name:'update_animation';
  animation-duration:1s;
  animation-iteration-count:10;
  animation-delay:3s;
}

update-notification-icon-ui .popup-menu > button {
  background-color:var(--body-color);
}

@keyframes update_animation {
  0% {
    box-shadow:0px 0px 0px 0px inset yellow;    
  }
  50% {
    box-shadow:0px 0px 3px 3px inset yellow;
  }
  100% {
    box-shadow:0px 0px 0px 0px inset yellow;    
  }
}