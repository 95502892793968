.coffee_source_root .field:first-of-type {
  position:relative;
}
.name-suggestion-container {
  background-color:var(--body-color);
  /* padding-inline:0.5em; */
  border-style:solid;
  border-width:1px;  
  position:absolute;
  cursor:pointer;
  top:100%;
  left:1rem;
}
.name-suggestion-container.hidden {
  display:none;
}
.name-suggestion-container .name {
  padding-inline:0.2rem;
  padding-top:0.2rem;
  background-color:var(--header-color);
}
.name-suggestion-container .name-group {
  display:flex;
  flex-direction:column;
  overflow:hidden;
}
.name-suggestion-container .instance {
  display:flex;
  flex-direction:row;
  padding-left:3ch;
  width:100%;
  padding-block:0.2rem;
}
.name-suggestion-container .instance span {
  min-width:13ch;
  width:13ch;
}
/*.name-suggestion-container > div {
  display:flex;  
  flex-direction:row;
  overflow:hidden;
}

.name-suggestion-container > div > * {
  padding-inline:0.25rem;
  padding-block:0.25rem;
  border-right-style:solid;
  border-right-width:1px;
} 
.name-suggestion-container > div > *:last-child {
  border-right-style:none;
} */